// Generated by Framer (a1ca433)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Page, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";

const cycleOrder = ["w3nf0lbz9"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"w3nf0lbz9": "framer-v-1w0qv61"};

if (isBrowser) removeHiddenBreakpointLayers("w3nf0lbz9", breakpoints, variantClassNames);

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, width, height, layoutId, variant: outerVariant = "w3nf0lbz9", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "w3nf0lbz9", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div className="framer-ujTDA" style={{"display": "contents"}}>
<motion.div {...restProps} className={cx("framer-1w0qv61", className)} style={{...style}} name="Desktop" transition={transition} ref={ref} {...addVariantProps("w3nf0lbz9")}><Page className="framer-5sz4m2" __fromCanvasComponent={true} direction="horizontal" directionLock={true} contentWidth="stretch" contentHeight="stretch" alignment="start" gap={0} padding={0} paddingPerSide={false} paddingTop={0} paddingRight={0} paddingBottom={0} paddingLeft={0} overflow="hidden" currentPage={0} momentum={false} dragEnabled={true} wheelEnabled={false} defaultEffect="none" __fromCodeComponentNode={true} background={null} _border={{}} transition={transition} {...addVariantProps("wwgWRjPTe")}/></motion.div>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-ujTDA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ujTDA .framer-1w0qv61 { position: relative; overflow: hidden; width: 1200px; height: 2000px; background-color: #ffffff; }", ".framer-ujTDA .framer-5sz4m2 { position: absolute; height: 760px; right: 0px; left: 0px; top: 0px; flex: none; border-bottom-left-radius: 18px; border-bottom-right-radius: 18px; border-top-right-radius: 18px; border-top-left-radius: 18px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2000
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FramersMt2K8jwQ: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramersMt2K8jwQ;

FramersMt2K8jwQ.displayName = "Page 2";

FramersMt2K8jwQ.defaultProps = {"width": 1200, "height": 2000};

addFonts(FramersMt2K8jwQ, []);